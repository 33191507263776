import React, { useState } from 'react'
import { Link } from 'gatsby'
import { PageTemplate } from '../templates/page'
import { get, list, quantity, total } from 'cart-localstorage'
import SiteSettings from '../data/site-settings.json'
import Button from '../components/shortcodes/Button'
import CartPageStyles from '../styles/CartPage.module.sass'

const pageProps = {
  title: "My Order",
  subtitle: `We will contact you to confirm the orientation after you place the order. <br /><strong>For <u>custom sized cuts</u> or <u>custom centers</u> please call us on ${SiteSettings.phone}.<strong>`,
  pageIdentifier: "cart",
  pageClass: "hide-cart",
  showCTA: false,
  showContactForm: false,
}

const CartPage = () => {
  const [cart, setCart] = useState( 
    typeof window !== 'undefined' 
    ? list()
    : null
  )

  // Prevent Gatsby from prerendering this page
  if (typeof window == 'undefined') {
    return false
  }

  const maxQty = 500

  // Increase/Decreate quantity
  function changeQty(operation, id) {
    if (operation === 'decrease') {
      quantity(id, -1)
    } else {
      if (get(id).quantity >= maxQty) {
        return false
      } else {
        quantity(id, 1)
      }
    }

    setCart(list())
  }

  // Show cart if there are items in it, otherwise show a "cart empty" error
  if (cart && cart.length) {
    // Calculate totals
    let amountSubtotal = total().toFixed(2)
    let amountVat = (amountSubtotal * 0.23).toFixed(2)
    let amountTotal = (+amountSubtotal + +amountVat).toFixed(2)

    return (
      <PageTemplate
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCTA={pageProps.showCTA}
        showContactForm={pageProps.showContactForm}
      >
        <table className={`table ${CartPageStyles.table || ''}`}>
          <thead>
            <tr>
              <td>Product</td>
              <td>Price</td>
              <td>Qty</td>
              <td className="is-hidden-mobile"></td>
            </tr>
          </thead>
          <tbody>
            {cart.map(item => {
              return (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>&euro;{item.price.toFixed(2)}</td>
                  <td>
                    {item.quantity}
                    <div className="is-hidden-tablet">
                      <span
                        className={`tag ${CartPageStyles.increase || ''}`}
                        onClick={() => {
                          changeQty('increase', item.id)
                        }}
                        onKeyDown={() => {
                          changeQty('increase', item.id)
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        +
                      </span>
                      <span
                        className={`tag ${CartPageStyles.decrease || ''}`}
                        onClick={() => {
                          changeQty('decrease', item.id)
                        }}
                        onKeyDown={() => {
                          changeQty('decrease', item.id)
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        –
                      </span>
                    </div>
                  </td>
                  <td className="is-hidden-mobile">
                    <span
                      className={`tag ${CartPageStyles.increase || ''}`}
                      onClick={() => {
                        changeQty('increase', item.id)
                      }}
                      onKeyDown={() => {
                        changeQty('increase', item.id)
                      }}
                      role="button"
                      tabIndex="0"
                    >
                      +
                    </span>
                    <span
                      className={`tag ${CartPageStyles.decrease || ''}`}
                      onClick={() => {
                        changeQty('decrease', item.id)
                      }}
                      onKeyDown={() => {
                        changeQty('decrease', item.id)
                      }}
                      role="button"
                      tabIndex="0"
                    >
                      –
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className={CartPageStyles.actions || ''}>
          <div>
            <ul className={CartPageStyles.prices}>
              <li>Subtotal: &euro;{Number(amountSubtotal).toFixed(2)}</li>
              <li>VAT: &euro;{Number(amountVat).toFixed(2)}</li>
              <li><strong>Total: &euro;{Number(amountTotal).toFixed(2)}</strong></li>
            </ul>
            <Link
              to="/checkout"
              className={`button is-secondary ${CartPageStyles.pay || ''}`}
            >
              Pay Now
            </Link>
          </div>
        </div>
      </PageTemplate>
    )
  }
  else {
    return (
      <PageTemplate 
        title={pageProps.title}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCTA={pageProps.showCTA}
        showContactForm={pageProps.showContactForm}
      >
        <div className="has-text-centered">
          <div className="notification has-text-left">
            Your cart is empty.
          </div>
          <Button url="/" text="Shop Now" />
        </div>
      </PageTemplate>
    )
  }
}

export default CartPage